import * as types from '../actions/actionTypes'

const initState = {
  doctorTab: 'все',
  IdSpeciality: null
}

export default function doctorTabsReducer(state = initState, action) {
  switch (action.type) {
    case types.DOCTOR_TABS:
      return { ...state, doctorTab: action.doctorTab.doctorTab, IdSpeciality: action.doctorTab.IdSpeciality }
    default:
      return state
  }
}

import React from 'react'
import './index.scss'
import TimeButton from './TimeButton'

const ChooseTime = ({
  selectedTime,
  onSelectTime,
  times,
  isBusy,
  isCutSpeciatity,
}) => {
  return (
    <>
      <h3 className={`select-date-root__title title`}>Выберите время</h3>
      <div className="select-date-root__times-btns">
        {isCutSpeciatity !== undefined
          ? times
            .filter(
              (el) =>
                isCutSpeciatity.StartTime >= el &&
                el <= isCutSpeciatity.EndTime
            )
            .map((item, ndx) => (
              <TimeButton
                key={ndx}
                time={item}
                selected={item === selectedTime}
                onClick={onSelectTime}
                isBusy={isBusy}
              />
            ))
          : times.map((item, ndx) => (
            <TimeButton
              key={ndx}
              time={item}
              selected={item === selectedTime}
              onClick={onSelectTime}
              isBusy={isBusy}
            />
            ))}
      </div>
    </>
  )
}

export default ChooseTime

const Colors = {
  ACCENT: {
    main: "#ACC3FF",
    secondary: "#668FF9",
  },

  NEUTRAL: {
    main: "#000",
    secondary: "#9F9FC9",
    white: "#fff",
    grey: "#A5A4A8",
    mint: "#47CCBC",
    lightMint: "#DBF8F3",
    green: "#25DB83",
    lightGreen: "#CFF4E2",
    darkGreen: "#304858",
    darkBlue: "#304858",
  },

  ICON: {
    main: "#183b89",
    secondary: "#92d0e4",
  },
};

export default Colors;

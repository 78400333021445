import { makeStyles, TextField } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
}));

export function InputDateMask({
  value,
  onChange,
  className,
  label,
  propertyName,
  validation,
  ...props
}) {
  const classes = useStyles();

  return (
    <form
      className={["input-form", className].join(" ")}
    >
      <TextField
        className={classes.textField}
        label={label}
        variant="outlined"
        name={propertyName}
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
        {...props}
      />
    </form>
  );
}

import MenuItem from '@material-ui/core/MenuItem'
import React, {useState} from 'react'
import GeneralButton from '../../../../components/kit/GeneralButton/GeneralButton'
import InputForm from '../../../../components/kit/InputForm/InputForm'
import InputPhoneMask from '../../../../components/kit/InputPhoneMask/InputPhoneMask'
import SelectInput from '../../../../components/kit/SelectInput/SelectInput'
import { checkEmptyField } from '../../../../global/Helpers/checkEmptyField'
import { showGenderInput } from './../../../../constants.json'
import '../index.scss'
import { InputDateMask } from '../../../../components/kit/InputDateMask/InputDateMask'

const ClientForm = ({
  handleClickNext,
  name,
  lastname,
  gender,
  phone,
  inputChangeHandler,
  handleClickPrev,
  DateBirth,
  RegistrationNote,
}) => {
  const disabled =
    !checkEmptyField(name) ||
    !checkEmptyField(lastname) ||
    gender === -1 ||
    !checkEmptyField(phone) ||
    phone.includes('_') ||
    !checkEmptyField(DateBirth) 

  const [onFocusPhone, setOnFocusPhone] = useState(false)

  return (
    <div className="client-data-root">
      <h3 className="client-data-root__title title">Ваши контакты</h3>
      <form className="client-data-root__form">
        <InputForm
          className="client-data-root__field"
          type="text"
          value={name}
          propertyName="name"
          onChange={inputChangeHandler}
          label="Имя"
        />
        <InputForm
          className="client-data-root__field"
          type="text"
          value={lastname}
          propertyName="lastname"
          onChange={inputChangeHandler}
          label="Фамилия"
        />
        <InputDateMask
          className="client-data-root__field"
          type="date"
          value={DateBirth}
          propertyName="DateBirth"
          onChange={inputChangeHandler}
          label="Дата рождения"
        />
        {showGenderInput && (
          <SelectInput
            className="client-data-root__field"
            value={gender}
            name="gender"
            onChange={inputChangeHandler}
            label=""
          >
            <MenuItem value={-1}>Не выбран</MenuItem>
            <MenuItem value={1}>Мужской</MenuItem>
            <MenuItem value={0}>Женский</MenuItem>
          </SelectInput>
        )}

        <InputPhoneMask
          className="client-data-root__field "
          value={phone}
          propertyName="phone"
          onChange={inputChangeHandler}
          setOnFocusPhone={setOnFocusPhone}
        />
        <InputForm
          className="client-data-root__field"
          value={RegistrationNote}
          propertyName="RegistrationNote"
          onChange={inputChangeHandler}
          label="В случае приобретения стелек для обуви, напишите пожалуйста РОСТ и ВЕС"
          multiline
          rows={4}
        />

        {onFocusPhone ? <span className='input-on-focus'>Введите ваш актуальный номер телефона для получения подтверждения записи через СМС-сообщение.</span> : null}
      </form>
      <div className="d-flex mt-50 online-record-btns">
        <GeneralButton
          className="mr-10"
          title="Назад"
          onClick={handleClickPrev}
        />
        <GeneralButton
          title="Далее"
          disabled={disabled}
          onClick={handleClickNext}
        />
      </div>
    </div>
  )
}

export default ClientForm

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setDoctorTabs } from '../redux/actions/doctorTabsAction'
import _ from 'lodash'
import { useHorizontalScroll } from '../global/hooks/useHorizontalScroll'


const DoctorTab = ({ doctors, specialities }) => {
  const dispatch = useDispatch()
  const scrollRef = useHorizontalScroll()

  setTimeout(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlSpecialityId = parseInt(
      urlParams.get('specialityId') &&
        urlParams.get('specialityId').replace(/"/g, '')
    )
    const urlSpeciality = specialities.find((speciality) => {
      return speciality.Id === urlSpecialityId
    })
    urlSpeciality && dispatch(setDoctorTabs(urlSpeciality.Name))
  }, 400)

  const setDoctorTabHandler = (e) => {
    dispatch(setDoctorTabs({
		doctorTab: e.target.value,
		IdSpeciality: +e.currentTarget.id
	}))
  }

  const doctorTab = useSelector((s) => s.doctorTabsReducer.doctorTab).toLowerCase()

  const filtredSpecialities = _.uniqWith(
    doctors.reduce((acc, rec) => {
      return [
        ...acc,
        {
          SpecialityName: rec.SpecialityName,
          IdSpeciality: rec.IdSpeciality,
        },
      ]
    }, []),
    _.isEqual
  )

  return (
		<div
			className='choose-master-root__tabs'
			style={{ overflow: 'auto' }}
			ref={scrollRef}
		>
			<Link to={'/'}>
				<button
					className={`${
						doctorTab === 'все' ? 'active' : ''
					} btn choose-master-root__tab`}
					onClick={setDoctorTabHandler}
					value={'все'}
				>
					Все
				</button>
			</Link>
			{filtredSpecialities.map((doctor, idx) => {
				return (
					<Link to={`/`} key={idx}>
						<button
							className={`${
								doctorTab === doctor.SpecialityName.toLowerCase()
									? 'active'
									: ''
							} btn choose-master-root__tab`}
							onClick={setDoctorTabHandler}
							value={doctor.SpecialityName}
							id={doctor.IdSpeciality}
						>
							{doctor.SpecialityName}
						</button>
					</Link>
				)
			})}
		</div>
	)
}

export default DoctorTab

import React from "react"

const WalletIcon = ({ mainColor, secColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        fill="#91e085"
        d="M25.33,12.61,14,1.29a1,1,0,0,0-1.41,0L1.29,12.61a1,1,0,0,0,0,1.41L12.61,25.33a1,1,0,0,0,1.41,0L25.33,14A1,1,0,0,0,25.33,12.61Z"
      />
      <path
        fill="#84bf6f"
        d="M2.92,14.52a1,1,0,0,1,0-1.41L14.23,1.79a1.08,1.08,0,0,1,.18-.11L14,1.29a1,1,0,0,0-1.41,0L1.29,12.61a1,1,0,0,0,0,1.41L12.61,25.33a1,1,0,0,0,.7.3,1,1,0,0,0,.54-.18Z"
      />
      <path
        fill="#91e085"
        d="M30.71,12.61,19.39,1.29a1,1,0,0,0-1.41,0L6.67,12.61a1,1,0,0,0,0,1.41L18,25.33a1,1,0,0,0,1.41,0L30.71,14A1,1,0,0,0,30.71,12.61Z"
      />
      <path
        fill="#84bf6f"
        d="M8.29,14.77a1,1,0,0,1,0-1.41L19.6,2a1.15,1.15,0,0,1,.33-.21l-.54-.54a1,1,0,0,0-1.41,0L6.67,12.61a1,1,0,0,0,0,1.41L18,25.33a1,1,0,0,0,.71.3,1,1,0,0,0,.38-.08Z"
      />
      <rect width="30" height="22" x="1" y="9" fill={secColor} rx="1" ry="1" />
      <path
        fill={"#00000020"}
        d="M30,9H2a1,1,0,0,0-1,1v.17H28.63a1,1,0,0,1,1,1V31H30a1,1,0,0,0,1-1V10A1,1,0,0,0,30,9Z"
      />
      <path
        fill={mainColor}
        d="M31,16H25.18A4.12,4.12,0,0,0,21,19.6,4,4,0,0,0,25,24h6Z"
      />
      <rect width="1.38" height="8" x="29.63" y="16" fill={"#00000020"} />
    </svg>
  )
}

export default WalletIcon

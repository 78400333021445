import React from 'react'
import { Route, Switch } from 'react-router-dom'
import OnlineRecord from './containers/Pages/OnlineRecord/OnlineRecord'
import SharePage from './containers/Pages/SharePage/SharePage'
import { ROUTE_RECORD } from './global/Constants/Constants'

const Routes = () => {
  return (
    <>
      {!window.location.href.includes('shareShow') && (
        <Switch>
          <Route path={ROUTE_RECORD} exact component={OnlineRecord} />
        </Switch>
      )}

      {window.location.href.includes('shareShow') && <SharePage />}
    </>
  )
}

export default Routes

import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { BASE_URL } from '../../../global/Constants/Constants'
import { getPhotos, getNearestFreeTime } from '../../../constants.json'
import LoadingComponent from '../OnlineRecord/LoadingComponent/LoadingComponent'
import './SharePage.scss'
import _ from 'lodash'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SharePage = () => {
  const [doctors, setDoctors] = useState([])

  const MySwal = withReactContent(Swal)

  function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      MySwal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Скопировано в буфер обмена',
        showConfirmButton: false,
        timer: 1000,
      })
      return navigator.clipboard.writeText(textToCopy)
    } else {
      let textArea = document.createElement('textarea')
      textArea.value = textToCopy
      textArea.style.position = 'fixed'
      textArea.style.left = '-999999px'
      textArea.style.top = '-999999px'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej()
        textArea.remove()
        MySwal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Скопировано в буфер обмена',
          showConfirmButton: false,
          timer: 1000,
        })
      })
    }
  }

  axios({
    method: 'GET',
    headers: {
      Session_Id: localStorage.getItem('Session_Id'),
    },
    url: `${BASE_URL}api/Doctor/GetVisibleDoctors`,
    params: {
      isShowInSchedule: true,
      isShowPhoto: getPhotos,
      isAddNearestFreeTime: getNearestFreeTime,
    },
  }).then(({ data }) => {
    setDoctors(data.Data)
  })

  const filtredSpecialities = _.uniqWith(
    doctors.reduce((acc, rec) => {
      return [
        ...acc,
        {
          SpecialityName: rec.SpecialityName,
          IdSpeciality: rec.IdSpeciality,
        },
      ]
    }, []),
    _.isEqual
  )

  return doctors.length > 0 ? (
    <div>
      <div className={'doctors__links-block'}>
        <div className={'doctors__links-title'}>
          Ссылки чтобы поделиться врачами (Нажмите чтобы скопировать)
        </div>

        {doctors.map((el, idx) => (
          <div className={'doctors__links-link'} key={idx}>
            <div>
              {el.Name} - ({el.SpecialityName})
            </div>
            <div
              className={'doctors__links-link-href'}
              onClick={() =>
                copyToClipboard(
                  `${window.location.origin}/?doctorId="${el.Id}"`
                )
              }
            >
              {`${window.location.origin}/?doctorId="${el.Id}"`}
            </div>
          </div>
        ))}
      </div>
      <div className={'doctors__links-block'}>
        <div className={'doctors__links-title'}>
          Ссылки чтобы поделиться специальностями (Нажмите чтобы скопировать)
        </div>
        {filtredSpecialities.map((el, idx) => (
          <div className={'doctors__links-link'} key={idx}>
            <div>{el.SpecialityName}</div>
            <div
              className={'doctors__links-link-href'}
              onClick={() =>
                copyToClipboard(
                  `${window.location.origin}/?specialityId="${el.IdSpeciality}"`
                )
              }
            >{`${window.location.origin}/?specialityId="${el.IdSpeciality}"`}</div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <LoadingComponent />
  )
}

export default SharePage

import React from 'react';
import { useSelector } from 'react-redux';

const SelectedDoctor = ({ master, doctorSpecialities, masterPhoto }) => {
  const selectedSpeciality = useSelector((s) => s.doctorTabsReducer.doctorTab)
  return (
    <div>
      <div className="d-flex choose-master-root__master justify-content-start align-items-center">
        <div className="choose-master-root__avatar">
          {masterPhoto ? (
            <img
              src={`data:image/png;base64,${masterPhoto}`}
              alt=""
              style={{
                width: '78px',
                height: '78px',
                borderRadius: '15px',
                border: 'none',
                objectFit: 'cover',
              }}
            />
          ) : (
            master.charAt().toUpperCase()
          )}
        </div>
        <div className="d-flex flex-column">
          <div className="choose-master-root__master-name">
            {master.toLowerCase()}
          </div>
          <p className="choose-master-root__speciality">
            {selectedSpeciality === 'все' ? doctorSpecialities[0].Name : selectedSpeciality}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SelectedDoctor;